<template>
  <div>
    <div class="e-breadcrumb">
      <span style="margin-left: -10px" @click="closeEvent">项目提成配置管理</span>
      > 设置统计内容
    </div>
      <div class="e-table-wrapper">
        <table cellpadding="0" cellspacing="0" border="0" width="68%" style="text-align: center">
          <thead>
          <tr>
            <th width="80" class="th-l">项目编号</th>
            <th width="130" class="th-l">项目名称</th>
            <th width="130" class="th-l">项目个数</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in itemList" :key="item.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
            <td class="td-l"><span>{{item.itemNo}}</span></td>
            <td class="td-l"><span>{{item.itemName}}</span></td>
            <td class="td-l">
              <el-input style="width: 88%"  size="small" onkeyup="value=value.replace(/[^(\d||/.)]/g,'')"  v-model="item.baseValue" placeholder="请输入内容"></el-input>
            </td>
          </tr>
          </tbody>
        </table>
        <div style="margin-bottom: 30px; margin-top: 30px; text-align: center">
          <el-button size="small" @click="closeEvent">返 回</el-button>
          <el-button size="small" type="primary" @click="saveMultipleBase"
          >确 定</el-button
          >
        </div>
      </div>
    </div>
</template>
<script>
import { Base_url } from "../../config/index";

export default {
  components: {},

  data() {
    return {
      Base_url: "",
      value:'',


      itemList:[],
    };
  },

  created() {
    this.Base_url = Base_url;
    this.getItemList();
  },

  methods: {
    async getItemList() {
      let res = await this.$get("/admin/getItemListNoPage");
      res.list.forEach(item=>{
         let data ={
           itemId:item.id,
           itemNo:item.itemNo,
           itemName:item.itemName,
           baseValue:item.baseValue==null?'1':item.baseValue
         }
         this.itemList.push(data)
      })
    },
   async saveMultipleBase(){
      let data ={
        itemJson:JSON.stringify(this.itemList)
      }
      console.log(data)
      let res = await this.$post("/admin/saveMultipleBase",data);
      if (res.code==200){
        this.closeEvent()
        this.$message.success(res.message)
      }else {
        this.$message.error(res.message)
      }
    },
    closeEvent() {
      this.$router.push("/commis/itemCommission");
    },
  },
};
</script>
<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  .td-l {
    padding: 1px 2px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 2px 0px;
  }
  .off {
    background-color: #D5D5D5;
  }
  .on {
    background-color: #ececea;
  }
</style>
